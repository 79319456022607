import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private ApiService: ApiService,
        private UserMenuService: UserMenuService,
        private StorageService: StorageService,
        private UtilsService: UtilsService
    ) { }

    async saveUser(params): Promise<any> {
        params.url = "user";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async saveUserData(params): Promise<any> {
        params.url = "user/data";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async saveUserAddress(params): Promise<any> {
        params.url = "user/address";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async login(params): Promise<any> {
        params.url = "user/login";
        params["encrypt"] = true;
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                return_data.data = this.UtilsService.decryptData(return_data.data);
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async getUser(params): Promise<any> {
        params.url = "user/id";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.get(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async verifyUserExist(params): Promise<any> {
        params.url = "user/verify";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async getUserList(params): Promise<any> {
        params.url = "user";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.get(params, true).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async getDashboardInfo(params): Promise<any> {
        params.url = "user/dashboard";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async saveUserTeam(params): Promise<any> {
        params.url = "user/team";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async deleteUserTeam(params): Promise<any> {
        params.url = "user/team";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.delete(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    async saveUserInvoice(params): Promise<any> {
        params.url = "user/invoice";
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post(params).subscribe(return_user => {
                return_data = return_user;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });

        });
        return await promise;
    }
    updateUser(data) {
        this.UserMenuService.setUser(new User(data));
    }

    async isLoggedIn() {
        return (await this.StorageService.get('user')) ? true : false;
    }
}

export class UserMenuService {

    public user: User;
    private userSubject: any;

    constructor() {
        this.userSubject = new BehaviorSubject(this.user);
    }

    getUserObservable(): Observable<User> {
        return this.userSubject.asObservable();
    }

    public setUser(user: User) {
        this.user = user;
        this.userSubject.next(this.user);
    }
}
export class User {
    public User: any;

    constructor(user: any) {
        this.User = user;
    }

    getUser() {
        return this.User;
    }
}