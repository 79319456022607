import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-popover-select',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
})
export class ItemComponent implements OnInit {
    @Input() multiple: boolean;
    @Input() list: any = [];
    @Input() search_key: string;
    public Controls = { search_text: "", list: [] };
    constructor(
        private PopoverController: PopoverController
    ) { }

    ngOnInit(): void {
        this.Controls.list = this.list = this.list.map(item => {
            item["selected"] = false;
            return item;
        });
    }
    onSearchChange(event) {
        let search = event.detail.value.toLowerCase();
        if (this.search_key) {
            this.Controls.list = this.list.filter(item => {
                const key = item[this.search_key];
                return (key as string).toLowerCase().includes(search)
            });
        }
    }

    itemSelected(item) {
        item.selected = !item.selected;
        if (this.multiple) {
            this.list = this.list.map(_item => {
                (_item[this.search_key] == item[this.search_key]) ? _item["selected"] = item.selected : null;
                return _item;
            });
            this.Controls.list = this.Controls.list.map(_item => {
                (_item[this.search_key] == item[this.search_key]) ? _item["selected"] = item.selected : null;
                return _item;
            });
        } else {
            this.list = this.list.map(_item => {
                _item["selected"] = _item[this.search_key] == item[this.search_key]
                return _item;
            });
            this.Controls.list = this.Controls.list.map(_item => {
                _item["selected"] = _item[this.search_key] == item[this.search_key]
                return _item;
            });
        }
    }

    confirm() {
        if (this.multiple) {
            return this.PopoverController.dismiss(this.list.filter(item => item["selected"]), "dismiss");
        } else {
            return this.PopoverController.dismiss(this.list.find(item => item["selected"]), "dismiss");
        }
    }
}
