import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {
    static production: boolean = true;
    static readonly api_local: string = 'http://localhost:3000/';
    static readonly api_production: string = 'https://api.roboraveinternational.org/';
    static readonly api_key_google: string = 'API_KEY_GOOGLE&libraries=visualization,places,drawing';
    static readonly api_paypal_test: string = "AQBOmae0bK-JNXjDVxyUPLDg3TKQl73vVpEZJpJ55GZkdTme5DnoJaO3oHJ2BlUPZrxPcpkjs8mjai9q";
    static readonly api_paypal_donations: string = "Ad91dE6bwFVwsML3nn4S-TxmZC1X8wv8-X4r-i6eoA-9x3Vse1STuD-DLiFtFRAtjOIrHFSXdgp-T9Ij";
    static readonly paypal_donations_secret: string = "EKqZFufBYjcEalpWw1l0zYbJLHjNKdUVxdAEj7HxfcNN-ErxPMABBIE0dFmQp5f29HVqXJR7vwRHt1Lo";
    static readonly master_key: string = 'RRI_master_encript_2023';
    static readonly master_key_api: string = 'roboRAVE_international_ORG';
    static readonly socket: string = 'https://socket.roboraveinternational.org:2999';
    static api_url: string = (AppConfig.production) ? AppConfig.api_production : AppConfig.api_local;
    static web_url: string = (AppConfig.production) ? "https://roboraveinternational.org/" : "http://localhost:8100/";
}
