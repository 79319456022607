<ion-header>
    <ion-toolbar>
        <ion-searchbar placeholder="Buscar" type="text"
            (ionChange)="onSearchChange($event)" [debounce]="250"></ion-searchbar>
    </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="false" style="max-height: 358px;">
    <ion-list>
        <ion-item *ngFor="let item of Controls.list" [ngClass]="{'item-selected': item.selected}" class="pointer item-selected" (click)="itemSelected(item)">
            <ion-icon slot="start"  name="chevron-forward-outline"></ion-icon>
            <div>{{item[search_key]}}</div>
        </ion-item>
    </ion-list>
</ion-content>
<ion-footer>
    <ion-toolbar (click)="confirm()" color="success" class="pointer">
        <ion-title>Aceptar</ion-title>
    </ion-toolbar>
</ion-footer>