import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoginGuard implements CanActivateChild, CanActivate {
    constructor(
        private UtilsService: UtilsService,
        private TranslateService: TranslateService,
        private StorageService: StorageService
    ) { }

    public canActivateChild() {
        if (localStorage.getItem('logged') == '1') {
            if (localStorage.getItem('expired') == '1') {
                localStorage.clear();
                this.UtilsService.Router.navigateByUrl('/');
                return false;
            } else {
                this.UtilsService.Storage.get("user").then(user => {
                    if (user && user.verified_at) {
                    } else {
                        this.UtilsService.Router.navigate(['/profile']);
                    }
                });
                return true;
            }
        } else {
            localStorage.clear();
            this.UtilsService.Router.navigateByUrl('/');
            return false;
        }
    }

    public async canActivate() {
        let User = await this.StorageService.get("user");
        if (User) {
            // this.TranslateService.use(User.user_data.language);
            this.UtilsService.Router.navigate([(User.user.user_role_id == 1)? '/events' : '/judge_tournament']);
            return false;
        } else {
            // this.TranslateService.use((getUserLocale().includes("es")) ? 'es' : 'en');
            window.scroll(0,0);
            return true;
        }
    }
}
