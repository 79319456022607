import { Component, OnInit } from '@angular/core';
import { RowService } from 'src/app/services/row.service';
import { ToastService } from 'src/app/services/toast.service';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-table',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
})
export class ItemComponent implements OnInit {
    public Controls = { search_text: "", clients: [], _clients:[] };
    constructor(
        private RowService: RowService,
        private ToastService: ToastService,
        private PopoverController: PopoverController

    ) { }

    ngOnInit(): void {
        this.loadClients();
    }
    onSearchChange(event) {
        // console.log(event.detail.value);
        let search = event.detail.value.toLowerCase();
        this.Controls.clients = this.Controls._clients.filter(client => client.name.toLowerCase().includes(search)); 
    }

    async loadClients() {
        let return_clients = await this.RowService.getListRow({ table: "client" });
        if (return_clients.success) {
            if (return_clients.data.length > 0) {
                this.Controls.clients = return_clients.data;
                this.Controls._clients = return_clients.data;
            } else {
                this.ToastService.presentToast("No hay ningun Cliente registrado", "sad");
            }
        } else {
            this.ToastService.presentToast("Error de conexión con la base de datos", "sad");
        }
    }

    clientSelected(client) {
        return this.PopoverController.dismiss(client,"dismiss");
    }
}
