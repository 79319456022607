import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LoginGuard } from 'src/app/guards/login-guard.service';
import { AdminGuard } from 'src/app/guards/admin-guard.service';
import { JudgeGuard } from 'src/app/guards/judge-guard.service';
import { AdminJudgeGuard } from 'src/app/guards/admin-judge-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'screencast', loadChildren: () => import('src/pages/screencast/view/module').then(m => m.PageModule) },

  // login
  { path: 'login', loadChildren: () => import('src/pages/login/module').then(m => m.PageModule), canActivate: [LoginGuard] },

  // Admin
  { path: 'events', loadChildren: () => import('src/pages/events/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'team/:team_id', loadChildren: () => import('src/pages/team_pesaje/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'challenges/:event_id/:challenge_id', loadChildren: () => import('src/pages/teams/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'judgement_criteria', loadChildren: () => import('src/pages/judgement_criteria/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'challenges/:event_id', loadChildren: () => import('src/pages/challenges/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'event_judgement_criteria/:event_id', loadChildren: () => import('src/pages/event_judgement_criteria/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'judges', loadChildren: () => import('src/pages/judges/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'screencast/edit', loadChildren: () => import('src/pages/screencast/save/module').then(m => m.PageModule), canActivate: [AdminGuard] },
  { path: 'tickets/:event_id', loadChildren: () => import('src/pages/tickets/module').then(m => m.PageModule), canActivate: [AdminGuard] },

  // Judge
  { path: 'judge_tournament', loadChildren: () => import('src/pages/judge/judge_tournament/module').then(m => m.PageModule), canActivate: [AdminJudgeGuard] },
  { path: 'judge_tournament_qualifying/:event_tournament_id', loadChildren: () => import('src/pages/judge/judge_qualifying/module').then(m => m.PageModule), canActivate: [AdminJudgeGuard] },

  // template
  { path: 'docs', loadChildren: () => import('src/pages/docs/module').then(m => m.PageModule) },
  { path: 'blank_page', loadChildren: () => import('src/pages/blank_page/module').then(m => m.PageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
