<ion-header>
    <ion-toolbar>
        <ion-searchbar placeholder="Buscar" type="text"
            (ionChange)="onSearchChange($event)" [debounce]="250"></ion-searchbar>
    </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="false" style="max-height: 358px;">
    <ion-list>
        <ion-item class="pointer item-selected" *ngFor="let item of Controls.clients" (click)="clientSelected(item)">
            <ion-avatar slot="start">
                <img src="assets/icon/perfil-del-usuario.png" />
            </ion-avatar>
            <ion-label>{{item.name}}</ion-label>
        </ion-item>
    </ion-list>
</ion-content>