import { Component, OnInit } from '@angular/core';
import { RowService } from 'src/app/services/row.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from "src/app/services/storage.service";
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [];
  public User = { user: { user_role_id: 0 } };
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  Business: any = { image: "assets/roborave_logo_white.svg" };
  constructor(private RowService: RowService,
    private ToastService: ToastService,
    private TranslateService: TranslateService,
    private StorageService: StorageService,
    private NavController: NavController
  ) { }
  ngOnInit(): void {
    this.TranslateService.setDefaultLang('en');
    this.loadUser();
    this.loadData();
  }

  async loadData() {

  }

  async loadUser() {
    this.User = await this.StorageService.get("user");
    if (!this.User) return;
    switch (this.User.user.user_role_id) {
      case 1:
        this.appPages = [
          { title: 'Eventos', url: '/events', icon: 'calendar' },
          { title: 'Criterios de juicio', url: '/judgement_criteria', icon: 'list' },
          { title: 'Jueces', url: '/judges', icon: '', src: "assets/icon/judge.svg" },
          { title: 'Torneos', url: '/judge_tournament', icon: 'trophy', src: "" },
          { title: 'Screencast', url: '/screencast/edit', icon: '', src: "assets/icon/screen-cast.svg" },
        ];
        break;
      case 3:
        this.appPages = [
          { title: 'Torneos', url: '/judge_tournament', icon: 'trophy', src: "" },
        ];
        break;
      default:
        break;
    }
  }

  async logout() {
    await this.StorageService.clear();
    this.NavController.navigateRoot(['/login', { replaceUrl: true }]);
  }
}
