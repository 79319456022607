import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';
import { ItemComponent as PopoverClients } from "src/app/components/popover-clients/component";
import { ItemComponent as PopoverSelect } from "src/app/components/popover-select/component";
import { UserMenuService } from 'src/app/services/user.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { LoginGuard } from 'src/app/guards/login-guard.service';
import { AdminGuard } from 'src/app/guards/admin-guard.service';
import { JudgeGuard } from 'src/app/guards/judge-guard.service';
import { AdminJudgeGuard } from 'src/app/guards/admin-judge-guard.service';
@NgModule({
  declarations: [AppComponent, PopoverClients, PopoverSelect],
  imports: [TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
  }), BrowserModule, IonicModule.forRoot({ mode: 'ios' }), AppRoutingModule, BrowserAnimationsModule, IonicStorageModule.forRoot({
    name: 'ScoringRRI_db',
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  }), HttpClientModule,],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, UserMenuService, DatePipe, LoginGuard, AdminGuard, JudgeGuard, AdminJudgeGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}