import { Injectable } from '@angular/core';
import { ToastController, ToastButton } from '@ionic/angular';
@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(
        private ToastController: ToastController,
    ) {
    }
    async presentToast(message: string, icon: string, color?: string, time?: number) {
        const toast = await this.ToastController.create({
            mode: "md",
            message: message,
            duration: (time) ? time : 2000,
            icon: icon,
            color: (color) ? color : "theme",
            translucent: true,
            buttons: [{ side: 'end', icon: 'close', text: '', cssClass: '', handler: () => { } }]
        });
        toast.present();
    }
}