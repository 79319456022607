import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from 'src/app/services/user.service';
// import { getUserLocale } from 'get-user-locale';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/services/storage.service';

@Injectable()
export class JudgeGuard implements CanActivate {
    constructor(
        private UserService: UserService,
        private UtilsService: UtilsService,
        private StorageService: StorageService,
        private TranslateService: TranslateService
    ) { }

    public async canActivate() {
        let User = await this.StorageService.get("user");

        if (User && [3].includes(User.user.user_role_id)) {
            this.UserService.updateUser(User);
            // this.TranslateService.use(User.user_data.language);
            window.scroll(0, 0);
            return true;
        } else {
            // this.TranslateService.use((getUserLocale().includes("es")) ? 'es' : 'en');
            this.UtilsService.Router.navigateByUrl("/login");
            return false;
        }
    }
}
