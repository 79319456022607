import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AppConfig } from 'src/app/app.config';
// import { NgxImageCompressService } from 'ngx-image-compress';
import { DatePipe, Location } from '@angular/common';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser'
import * as CryptoJS from 'crypto-js';
// import { NgxSpinnerService } from "ngx-spinner";
// import { getUserLocale } from 'get-user-locale';
// import domtoimage from 'dom-to-image';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    private image_compress_options = { RATIO: 75, COMPRESS: 60 };

    constructor(
        // private NgxImageCompressService: NgxImageCompressService,
        private DatePipe: DatePipe,
        private sanitizer: DomSanitizer,
        public Storage: Storage,
        public Location: Location,
        public Router: Router,
        // public NgxSpinnerService: NgxSpinnerService
    ) { }

    async goBottom(content) {
        await content.scrollToBottom(0);
        await content.scrollToBottom(0);
    }

    consoleLog(data: any, params?: any) {
        if (!AppConfig.production) {
            (params) ? console.log(data, params) : console.log(data);
        }
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    // selectFile = () => {
    //     let promise = new Promise((resolve, reject) => {
    //         this.NgxImageCompressService.uploadFile().then(({ image, orientation }) => {
    //             if (this.NgxImageCompressService.byteCount(image) < 1048576) {
    //                 resolve(image);
    //                 console.warn('Size in bytes was:', this.NgxImageCompressService.byteCount(image));
    //             } else {
    //                 resolve(this.compressFile(image, orientation, this.image_compress_options.RATIO, this.image_compress_options.COMPRESS));
    //             }
    //         }).catch(error => resolve(false));
    //     });
    //     return promise;
    // };

    // compressFile(image, orientation, ratio, compress) {
    //     let promise = new Promise((resolve, reject) => {
    //         this.NgxImageCompressService.compressFile(image, orientation, ratio, compress).then(
    //             result => {
    //                 if (this.NgxImageCompressService.byteCount(result) < 1048576) {
    //                     // console.warn('Size in bytes was:', this.NgxImageCompressService.byteCount(result));
    //                     resolve(result);
    //                 } else {
    //                     resolve(this.compressFile(image, orientation, ratio, compress - 5));
    //                 }
    //             }
    //         );
    //     });
    //     return promise;
    // }

    getFileType(file) {
        var block = file.split(";");
        var contentType = block[0].split(":")[1];
        return "." + contentType.split("/")[1];
    }

    fileB64toBlob(file) {
        var block = file.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var sliceSize = 512;
        var byteCharacters = atob(realData);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    async sleep(ms) {
        return await new Promise(resolve => setTimeout(resolve, ms));
    }

    mktime(hour?, minute?, month?, day?, year?) {
        if (hour && minute && month && day && year) {
            return ((new Date(year, month, day, hour, minute, 0)).getTime() / 1000).toFixed(0);
        } else {
            return (new Date().getTime() / 1000).toFixed(0);
        }
    }

    dateTransform(date, type?) { return this.DatePipe.transform((date) ? date : new Date(), (type) ? type : 'd/M/yy, h:m a'); }

    setSlides(data, rows) {
        let return_data = [];
        let slide = [];
        let count = 0;
        let total = 0;
        for (const key in data) {
            count++;
            total++;
            if (count % rows == 0 || total == data.length) {
                slide.push(data[key]);
                return_data.push(slide);
                count = 0;
                slide = [];
            } else {
                slide.push(data[key]);
            }
        }
        return return_data;
    }

    bypassSecurityTrustStyle(url) {
        return this.sanitizer.bypassSecurityTrustStyle("url('" + url + "')");
    }

    bypassSecurityTrustStyleCustom(style) {
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }

    bypassSecurityTrustHtml(code) {
        return this.sanitizer.bypassSecurityTrustHtml(code);
    }

    /**
     * The function `sortJSON` sorts an array of JSON objects based on a specified key in either ascending
     * or descending order.
     * @param data - The `data` parameter is an array of JSON objects that you want to sort.
     * @param key - The key parameter is the property of the JSON objects that you want to sort by. It is
     * the property that you want to compare when sorting the JSON array.
     * @param orden - The parameter "orden" is used to specify the sorting order. It can have two possible
     * values: "asc" for ascending order and "desc" for descending order.
     * @returns The function `sortJSON` is returning the sorted `data` array based on the `key` and `orden`
     * parameters.
     */
    sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];

            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }

            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }

    objectToFormData(data): FormData {
        let return_data = new FormData();
        for (const key in data) {
            return_data.append(key, data[key]);
            /* console.log(data[key]); */
        }
        return return_data;
    }

    deleteNullAtributes(data) {
        for (const key in data) {
            if (data[key] === 'null' || data[key] === null) {
                delete data[key];
            }
        }
        return data;
    }

    // async getStorage(key): Promise<any> {
    //     let promise = new Promise<any>(async (resolve, reject) => {
    //         this.Storage.get(key).then(value => {
    //             resolve(this.decryptData(value));
    //         });
    //     });
    //     return await promise;
    // }

    // async setStorage(key, data): Promise<any> {
    //     let promise = new Promise<any>(async (resolve, reject) => {
    //         let encrypt_data = this.encryptData(data);
    //         this.Storage.set(key, encrypt_data).then(value => {
    //             resolve(this.decryptData(value));
    //         });
    //     });
    //     return await promise;
    // }

    encryptData(data) {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), AppConfig.master_key).toString();
        } catch (e) {
            console.log(e);
        }
    }

    decryptData(data) {
        try {
            const bytes = CryptoJS.AES.decrypt(data, AppConfig.master_key);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    addRomoveFixedaNavbar(enable, mode) {
        switch (mode) {
            case "add":
                if (enable == "1") {
                    var navbar = document.getElementsByTagName('nav')[0];
                    navbar.classList.remove('fixed-top');
                }
                break;
            case "remove":
                if (enable == "1") {
                    var navbar = document.getElementsByTagName('nav')[0];
                    navbar.classList.add('fixed-top');
                }
                break;

            default:
                break;
        }
    }

    fileToB64(file: File): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
        return promise;
    }

    // async getLanguage(): Promise<string> {
    //     let User = await this.getStorage("user");
    //     if (User) {
    //         return User.user_data.language;
    //     } else {
    //         return (getUserLocale().includes("es")) ? 'es' : 'en';
    //     }
    // }

    // makePNGFromHtml(element: HTMLElement) {
    //     let promise = new Promise((resolve, reject) => {
    //         domtoimage.toPng(element).then(function (dataUrl) {
    //             resolve(dataUrl);
    //         })
    //         .catch(function (error) {
    //             resolve(error)
    //         });
    //     });
    //     return promise;
    // }

    deleteDuplicates<T>(arr: T[]): T[] {
        return Array.from(new Set(arr));
    }

    /**
     * If the value is not an object, then it is a primitive value and can be copied by assignment. If the
     * value is an object, then it is either an array or a regular object. If it is an array, then it is
     * copied by value. If it is a regular object, then it is copied by reference.
     * </code>
     * @param {any} object - The object to be cloned.
     * @returns A clone of the object.
     */
    deepCloneObject(object: any) {
        var clone: any = {};
        for (var key in object) {
            var value = object[key];
            clone[key] = (typeof (value) != 'object') ? value : (!Array.isArray(value)) ? this.deepCloneObject(value) : value.slice(0);
        }
        return (!object) ? object : clone;
    }

    /**
     * The function shuffles the elements of an array randomly.
     * @param {any[]} list - The parameter "list" is an array of any data type.
     */
    getRandomArray(list: any[]) {
        for (var i = list.length - 1; i > 0; i--) {
            var random_index = Math.floor(Math.random() * (i + 1));
            var temp = list[i];
            list[i] = list[random_index];
            list[random_index] = temp;
        }
    }

    /**
     * If the data is JSON, return the parsed JSON, otherwise return the empty object.
     * @param data - The data to be parsed.
     * @param empty - The value to return if the data is not JSON.
     * @returns the result of the JSON.parse(data) function.
     */
    getJSONIfIsPossible(data: any, empty: any) {
        try {
            return JSON.parse(data);
        } catch (error) {
            return empty;
        }
    }

    arrayObjToCsv(ObjectToCSV, file_name) {
        if (window.Blob && (window.URL || window.webkitURL)) {
            var contenido = "",
                today = new Date(),
                blob,
                save,
                clicEvent;
            for (var i = 0; i < ObjectToCSV.length; i++) {
                if (i == 0) {
                    contenido += Object.keys(ObjectToCSV[i]).join(";") + "\n";
                }
                contenido += Object.keys(ObjectToCSV[i]).map(function (key) {
                    return ObjectToCSV[i][key];
                }).join(";") + "\n";
            }
            blob = new Blob(["\ufeff", contenido], { type: 'text/csv' });
            let reader = new FileReader();
            reader.onload = function (event) {
                save = document.createElement('a');
                save.href = event.target.result;
                save.target = '_blank';
                save.download = file_name + " " + today.getDate() + "_" + (today.getMonth() + 1) + "_" + today.getFullYear() + ".csv";
                try {
                    clicEvent = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': true
                    });
                } catch (e) {
                    clicEvent = document.createEvent("MouseEvent");
                    clicEvent.initEvent('click', true, true);
                }
                save.dispatchEvent(clicEvent);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
            reader.readAsDataURL(blob);
        } else {
            alert("Action no supported by web browser");
        }
    };
}
