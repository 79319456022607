import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AppConfig } from 'src/app/app.config';
import { UtilsService } from 'src/app/services/utils.service';
@Injectable({
    providedIn: 'root'
})
export class RowService {
    
    constructor(
        private ApiService: ApiService,
        private UtilsService: UtilsService
    ) { }

    // tag
    async saveRow(params): Promise<any> {
        params.url = "row/save";
        params["row_token"] = AppConfig.master_key_api;
        params["encrypt"] = true;
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post((params["encrypt"])? {encrypt: true, encrypted: this.UtilsService.encryptData(params), url: params.url} : params).subscribe(_return_data => {
                return_data = _return_data;
                return_data.data = this.UtilsService.decryptData(return_data.data);
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });
        });
        return await promise;
    }
    async saveRowFile(params): Promise<any> {
        params.append("url", "row/save");
        params.append("row_token", AppConfig.master_key_api);
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.postFormData(params).subscribe(_return_data => {
                return_data = _return_data;
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });
        });
        return await promise;
    }
    async getRow(params): Promise<any> {
        params.url = "row/get";
        params["encrypt"] = true;
        params["row_token"] = AppConfig.master_key_api;
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post((params["encrypt"])? {encrypt: true, encrypted: this.UtilsService.encryptData(params), url: params.url} : params).subscribe(_return_data => {
                return_data = _return_data;
                return_data.data = this.UtilsService.decryptData(return_data.data);
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });
        });
        return await promise;
    }
    async getListRow(params): Promise<any> {
        params.url = "row/list";
        params["encrypt"] = true;
        params["row_token"] = AppConfig.master_key_api;
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post((params["encrypt"])? {encrypt: true, encrypted: this.UtilsService.encryptData(params), url: params.url} : params).subscribe(_return_data => {
                return_data = _return_data;
                return_data.data = this.UtilsService.decryptData(return_data.data);
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });
        });
        return await promise;
    }
    async deleteRow(params): Promise<any> {
        params.url = "row/delete";
        params["encrypt"] = true;
        params["row_token"] = AppConfig.master_key_api;
        let return_data: any = { success: 0, data: null };
        let promise = new Promise<any>(async (resolve, reject) => {
            this.ApiService.post((params["encrypt"])? {encrypt: true, encrypted: this.UtilsService.encryptData(params), url: params.url} : params).subscribe(_return_data => {
                return_data = _return_data;
                return_data.data = this.UtilsService.decryptData(return_data.data);
                resolve(return_data);
            }, error => {
                return_data.data = error;
                resolve(return_data);
            });
        });
        return await promise;
    }

}